/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-return-assign */
import React, { useEffect } from "react";

import { classNames, getFeeRate } from "src/Hooks/util";
import Refresh from "../Icons/Refresh";
import TextFieldWithButton from "./TextFieldWithButton";

interface Props {
  className?: string;
  darkest?: boolean;
  label: string;
  optimalFee: number;
  fee: number;
  onFeeChange: (v: number) => void;
  onFeeRefresh: (v: number) => void;
}

const SetFeeRate: React.FC<Props> = ({ className = "", ...props }) => {
  const handleRefreshFeeRate = async () => {
    const feeRate = await getFeeRate();
    props.onFeeChange(feeRate);
    props.onFeeRefresh(feeRate);
  };

  useEffect(() => {
    const inputElements = document.querySelectorAll('[type="range"]');

    const handleInput = (inputElement) => {
      let isChanging = false;

      const setCSSProperty = () => {
        const percent =
          ((inputElement.value - inputElement.min) /
            (inputElement.max - inputElement.min)) *
          100;
        inputElement.style.setProperty(
          "--webkitProgressPercent",
          `${percent}%`
        );
      };

      const handleMove = () => {
        if (!isChanging) return;
        setCSSProperty();
      };
      const handleUpAndLeave = () => (isChanging = false);
      const handleDown = () => (isChanging = true);

      inputElement.addEventListener("mousemove", handleMove);
      inputElement.addEventListener("mousedown", handleDown);
      inputElement.addEventListener("mouseup", handleUpAndLeave);
      inputElement.addEventListener("mouseleave", handleUpAndLeave);
      inputElement.addEventListener("click", setCSSProperty);

      setCSSProperty();
    };

    inputElements.forEach(handleInput);
  }, [handleRefreshFeeRate]);

  return (
    <div className="flex flex-col justify-center w-full my-4 text-black dark:text-white">
      <p className="mb-1 text-sm font-light text-left text-gray-800 dark:text-gray-500">
        {props.label} (optimal fee: {props.optimalFee} sats/vByte)
      </p>
      <TextFieldWithButton
        type="number"
        min={1}
        max={props.optimalFee * 2}
        handleOnInputChange={(value) => props.onFeeChange(parseFloat(value))}
        inputValue={props.fee}
        handleOnClick={() => handleRefreshFeeRate()}
        buttonName="refresh"
        className="mb-6"
      />
      <input
        type="range"
        value={props.fee}
        min={props.optimalFee > 2 ? 1 : 0.1}
        max={props.optimalFee * 2}
        step={props.optimalFee > 2 ? 1 : 0.1}
        onChange={(e) => props.onFeeChange(parseFloat(e.target.value))}
        className="h-1 bg-darkmode-900 accent-yellow-500"
      />
      <div className="flex justify-between w-full px-2 text-xs text-gray-500">
        <span>min</span>
        <span>optimal fee: {props.optimalFee}</span>
        <span>max</span>
      </div>
    </div>
  );
};

export default SetFeeRate;
