/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as backend from "bitmask-core";
import { CARBONADO, DISABLE_LN, LNDHUBX } from "bitmask-core/constants";

import TextField from "src/Components/Inputs/TextField";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import PageWrapper2 from "src/Components/Layout/Wrappers/PageWrapper2";
import ErrorModal from "src/Components/Modals/Error";
import TextArea from "src/Components/Inputs/TextArea";
import { Location } from "src/types";
import { addBalance, getFeeRate } from "src/Hooks/util";
import FullScreenModal from "src/Components/Modals/FullScreenModal";
import ScanQR from "src/Components/Modals/Children/ScanQR";
import SetFeeRate from "src/Components/Inputs/SetFeeRate";
import { RgbInvoiceParams } from "src/types/views";
import TextFieldWithButton from "src/Components/Inputs/TextFieldWithButton";
import LightningNetwork from "src/Components/Icons/LightningNetwork";
import Bitcoin from "src/Components/Icons/Bitcoin";
import { canTransferAssets } from "src/aux/transferAssets";
import RgbAsset from "src/Components/Icons/RgbAsset";

const Send = () => {
  const navigate = useNavigate();
  const network = window.localStorage.getItem("network");
  const location = useLocation() as Location;
  const {
    type,
    asset,
    walletData,
    vault,
    locationHistory,
    lnCredentials,
    hash,
  } = location.state;
  const [option, setOption] = useState(type || "");
  const [lnInvoice, setLnInvoice] = useState("");
  const [lnBalance, setLnBalance] = useState(0);
  const [fee, setFee] = useState(0);
  const [optimalFee, setOptimalFee] = useState(0);
  const [openError, setOpenError] = useState(false);
  const [valueError, setValueError] = useState("");
  const [sendMax, setSendMax] = useState(false);
  const [openQrScanner, setOpenQrScanner] = useState(false);
  const [usernameInvoice, setUsernameInvoice] = useState({
    username: "",
    amount: 0,
  });
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [amount, setAmount] = useState(0);
  const [rgbInvoice, _setRgbInvoice] = useState<RgbInvoiceParams>(
    new RgbInvoiceParams(asset)
  );
  const [loading, setLoading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const streamRef = useRef<MediaStream | null>(null);

  const [transaction, setTransaction] =
    useState<backend.bitcoin.TransactionDetails>({
      sender: {
        name: walletData.name,
        address: walletData.address,
      },
      recipient: {
        name: "",
        address: "",
        invoice: "",
      },
      assetType: "",
      asset: "",
      amount: 0,
      fee: 0,
      message: "",
      txid: "",
      received: 0,
      sent: 0,
      confirmed: false,
      confirmationTime: {
        height: 0,
        timestamp: 0,
      },
      vsize: 0,
      feeRate: 0,
    });

  useEffect(() => {
    const getLnBalance = async () => {
      if (["Lightning", "Username"].includes(option) && LNDHUBX) {
        const tokens = await backend.lightning.auth(
          lnCredentials.login,
          lnCredentials.password
        );
        if ("error" in tokens) {
          console.error("ln auth token err");
        } else {
          const res = await backend.lightning.getBalance(tokens.token);
          setLnBalance(Number(res[0].balance));
        }
      }
    };
    getLnBalance();
  }, [option]);

  useEffect(() => {
    const setFeeRate = async () => {
      const feeRate = await getFeeRate();
      setOptimalFee(feeRate);
      setFee(feeRate);
    };
    setFeeRate();
  }, [option]);

  const handleSatSubmit = () => {
    const transactionData = {
      ...transaction,
      assetType: "sats",
    };
    if (transaction.amount >= 546) {
      if (Number(transaction.amount) % 1 !== 0) {
        setError({
          title: `Error creating transaction`,
          message: `You cannot send fractions of a ${
            network === "mainnet" ? "Sat" : "tSat"
          }. Please remove decimal places. If you are trying to introduce amount in bitcoins, convert to ${
            network !== "bitcoin" ? "tSats" : "Sats"
          }`,
        });
        setOpenError(true);
      } else if (
        Number(addBalance(walletData?.balance)) >= transaction.amount
      ) {
        transaction.fee = 1000;

        navigate("/confirm", {
          state: {
            ...location.state,
            transaction: transactionData,
            feeRate: fee,
            type: option,
            locationHistory: [...locationHistory, window.location.hash],
          },
        });
      } else {
        setError({
          title: `Error creating transaction`,
          message: "Insufficient funds",
        });
        setOpenError(true);
      }
    } else {
      setError({
        title: `Error creating transaction`,
        message: `You must send a minimum of 546 ${
          network !== "bitcoin" ? "tSats" : "Sats"
        }. Look into the dust limit and uneconomical outputs.`,
      });
      setOpenError(true);
    }
  };

  const handleTokenSubmit = async () => {
    setLoading(true);
    const [isValid, reason] = rgbInvoice.isValid();
    const result = await canTransferAssets(
      vault,
      asset,
      transaction.recipient?.invoice,
      fee !== 0 ? fee : optimalFee
    );

    const { valid, message } = result;

    if (!valid) {
      let newMessage = message;
      if (message.includes("open invoices"))
        newMessage += " Please close them before proceeding.";
      setError({
        title: `Error sending payment`,
        message: newMessage,
      });
      setOpenError(true);
      setLoading(false);
      return;
    }

    if (isValid) {
      // TODO: we have to check the data of the invoice and passs to next page
      const transactionData = {
        ...transaction,
        asset: rgbInvoice.contractId(),
        assetType: rgbInvoice.asseType(),
      };
      navigate("/confirm", {
        state: {
          ...location.state,
          transaction: transactionData,
          feeRate: fee,
          locationHistory: [...locationHistory, window.location.hash],
        },
      });
    } else {
      setError(reason);
      setOpenError(true);
    }
    setLoading(false);
  };

  const initializeQRScanner = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { width: 1280, height: 720 },
      });

      streamRef.current = stream;
      setScanning(true);
      setOpenQrScanner(true);
    } catch (err) {
      console.error("Error accessing camera:", err);
      setError({
        title: "Error Scanning QR",
        message:
          err instanceof Error
            ? `${err.name}: ${err.message}`
            : "An unknown error occurred",
      });
      setOpenError(true);
    }
  };

  const stopScanning = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => {
        track.stop();
        track.enabled = false;
      });
      streamRef.current = null;
    }
    setScanning(false);
    setOpenQrScanner(false);
  };

  useEffect(
    () => () => {
      stopScanning();
    },
    []
  );

  return (
    <>
      {option === "" && (
        <PageWrapper2
          className=""
          title="Send"
          handlePageBack={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, lnCredentials, hash },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, lnCredentials, hash },
            })
          }
          button={
            <RoundedButton
              className="w-full text-lg text-black dark:bg-yellow-500"
              onClick={() =>
                navigate("/wallet", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    lnCredentials,
                    hash,
                  },
                })
              }
            >
              Return to wallet
            </RoundedButton>
          }
        >
          <div className="flex flex-col justify-center w-11/12 m-auto space-y-4 xs:space-y-6">
            <RoundedButton
              className="text-base text-white bg-darkmode-700 lg:text-lg disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => setOption("Bitcoin")}
            >
              <div className="flex flex-col w-full px-3 py-1 sm:flex-row sm:flex-nowrap sm:px-6">
                <p className="pb-1 text-center sm:text-left sm:pb-0">
                  Send on-chain
                </p>
                <div className="flex mx-auto ml-auto sm:mr-0 flex-nowrap">
                  <Bitcoin className="w-6 h-6 my-auto mr-1.5" />
                  <p className="font-thin text-right text-gray-500">
                    Bitcoin (L1)
                  </p>
                </div>
              </div>
            </RoundedButton>
            <RoundedButton
              className="text-base text-white bg-darkmode-700 lg:text-lg disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => setOption("Lightning")}
              disabled={DISABLE_LN || !LNDHUBX || network !== "bitcoin"}
            >
              <div className="flex flex-col w-full px-3 py-1 sm:flex-row sm:flex-nowrap sm:px-6">
                <p className="pb-1 text-center sm:text-left sm:pb-0">
                  Send through invoice
                </p>
                <div className="flex mx-auto ml-auto sm:mr-0 flex-nowrap">
                  <LightningNetwork className="w-6 h-6 my-auto mr-1.5" />
                  <p className="font-thin text-right text-gray-500">
                    Lightning (L2)
                  </p>
                </div>
              </div>
            </RoundedButton>
            <RoundedButton
              className="text-base text-white bg-darkmode-700 lg:text-lg disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => setOption("Username")}
              disabled={DISABLE_LN || !LNDHUBX || network !== "bitcoin"}
            >
              <div className="flex flex-col w-full px-3 py-1 sm:flex-row sm:flex-nowrap sm:px-6">
                <p className="pb-1 text-center sm:text-left sm:pb-0">
                  {" "}
                  Send through username
                </p>
                <div className="flex mx-auto ml-auto sm:mr-0 flex-nowrap">
                  <LightningNetwork className="w-6 h-6 my-auto mr-1.5" />
                  <p className="font-thin text-right text-gray-500">
                    Lightning (L2)
                  </p>
                </div>
              </div>
            </RoundedButton>
            <RoundedButton
              className="text-base text-white bg-darkmode-700 lg:text-lg disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => setOption("Asset")}
              disabled={loading}
              loading={loading}
            >
              <div className="flex flex-col w-full px-3 py-1 sm:flex-row sm:flex-nowrap sm:px-6">
                <p className="pb-1 text-center sm:text-left sm:pb-0">
                  Send Asset
                </p>
                <div className="flex mx-auto ml-auto sm:mr-0 flex-nowrap">
                  <RgbAsset className="w-6 h-6 my-auto mr-1.5" />
                  <p className="font-thin text-right text-gray-500">RGB21</p>
                </div>
              </div>
              <div className="flex flex-col w-full px-3 py-1 sm:flex-row sm:flex-nowrap sm:px-6">
                <p className="pb-1 text-center sm:text-left sm:pb-0">
                  Send Asset
                </p>
                <div className="flex mx-auto ml-auto sm:mr-0 flex-nowrap">
                  <RgbAsset className="w-6 h-6 my-auto mr-1.5" />
                  <p className="font-thin text-right text-gray-500">RGB21</p>
                </div>
              </div>
            </RoundedButton>
            <RoundedButton
              className="text-base text-white bg-darkmode-700 lg:text-lg disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => setOption("Invoice")}
            >
              <div className="flex flex-col w-full px-3 py-1 sm:flex-row sm:flex-nowrap sm:px-6">
                <p className="pb-1 text-center sm:text-left sm:pb-0">
                  Pay Invoice
                </p>
                <div className="flex mx-auto ml-auto sm:mr-0 flex-nowrap">
                  <RgbAsset className="w-6 h-6 my-auto mr-1.5" />
                  <p className="font-thin text-right text-gray-500">RGB21</p>
                </div>
              </div>
              <div className="flex flex-col w-full px-3 py-1 sm:flex-row sm:flex-nowrap sm:px-6">
                <p className="pb-1 text-center sm:text-left sm:pb-0">
                  Pay Invoice
                </p>
                <div className="flex mx-auto ml-auto sm:mr-0 flex-nowrap">
                  <RgbAsset className="w-6 h-6 my-auto mr-1.5" />
                  <p className="font-thin text-right text-gray-500">RGB21</p>
                </div>
              </div>
            </RoundedButton>
          </div>
        </PageWrapper2>
      )}
      {option === "Bitcoin" && (
        <PageWrapper
          title={`Send on-chain ${network !== "bitcoin" ? "tSats" : "Sats"}`}
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, hash, lnCredentials },
            })
          }
          handleSubmit={() => handleSatSubmit()}
        >
          <div className="w-full">
            <TextFieldWithButton
              handleOnInputChange={(value) =>
                setTransaction({
                  ...transaction,
                  recipient: {
                    ...transaction.recipient,
                    address: value,
                    name: "recipient",
                  },
                })
              }
              inputValue={transaction.recipient.address}
              handleOnClick={() => {
                if (
                  !navigator.mediaDevices ||
                  !navigator.mediaDevices.getUserMedia
                ) {
                  setError({
                    title: "Error Opening Camera",
                    message: "getUserMedia is not supported on this device",
                  });
                  setOpenError(true);
                  return;
                }
                initializeQRScanner();
              }}
              type="text"
              placeholder="Enter recipient's address"
              label="To"
              buttonName="Scan QR"
              className=""
            />
            {sendMax ? (
              <div className="flex flex-col">
                <p className="mb-1.5 mt-4 text-sm font-light text-left text-gray-800 dark:text-gray-500">
                  {`Amount in ${network !== "bitcoin" ? "tSats" : "Sats"}`}{" "}
                </p>
                <div className="without-ring flex flex-nowrap w-full p-1.5 px-3 dark:bg-darkmode-700 bg-gray-200 focus:bg-gray-300 rounded-xl text-base focus:ring-darkmode-600 border-none focus:ring-1 disabled:opacity-50 disabled:cursor-not-allowed mx-auto focus:text-gray-900 placeholder-gray-600 dark:placeholder-gray-600 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-600">
                  <p className="w-full my-auto font-thin text-left text-gray-900 sm:text-base dark:text-gray-400">
                    Sending maximum amount of {walletData.balance.confirmed}{" "}
                    {network !== "bitcoin" ? "tSats" : "Sats"}
                  </p>{" "}
                  <RoundedButton
                    className="w-auto my-auto text-sm font-medium text-yellow-500 bg-darkmode-700 flex-shrink-default whitespace-nowrap"
                    onClick={() => {
                      setSendMax(false);
                      setAmount(0);
                      setTransaction({
                        ...transaction,
                        amount: 0,
                      });
                    }}
                  >
                    Cancel
                  </RoundedButton>
                </div>
              </div>
            ) : (
              <TextFieldWithButton
                handleOnInputChange={(value) => {
                  setAmount(Number(value));
                  setTransaction({
                    ...transaction,
                    amount: Number(value),
                  });
                }}
                handleOnClick={() => {
                  if (walletData.balance.confirmed >= 546) {
                    setSendMax(true);
                    setAmount(walletData.balance.confirmed);
                    setTransaction({
                      ...transaction,
                      amount: walletData.balance.confirmed,
                    });
                  } else {
                    setError({
                      title: "Available balance too low",
                      message: `Available balance is too low, you must send a minimum of 546 ${
                        network !== "bitcoin" ? "tSats" : "Sats"
                      }. Look into the dust limit and uneconomical outputs. Your available balance: ${
                        walletData.balance.confirmed
                      }.`,
                    });
                    setOpenError(true);
                  }
                }}
                type="number"
                label={`Amount in ${network !== "bitcoin" ? "tSats" : "Sats"}`}
                buttonName="Send Max"
                placeholder={`${walletData.balance.confirmed.toLocaleString()} ${
                  network !== "bitcoin" ? "tSats" : "Sats"
                } available to spend`}
                className="mt-4"
              />
            )}
            <TextArea
              type="text"
              label="Note"
              placeholder="Enter note"
              className="w-full p-3"
              rows={1}
              onChange={(e) =>
                setTransaction({ ...transaction, note: e.target.value })
              }
              onKeyDown={(e) =>
                e.key === "Enter" ? () => handleSatSubmit() : null
              }
            />
            <SetFeeRate
              label="Set Fee Rate"
              optimalFee={optimalFee}
              fee={fee}
              onFeeChange={(f) => setFee(f)}
              onFeeRefresh={(f) => setOptimalFee(f)}
            />
          </div>
        </PageWrapper>
      )}
      {option === "Lightning" && (
        <PageWrapper
          title="Pay Lightning Invoice"
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, lnCredentials, hash },
            })
          }
          handleSubmit={() => {
            try {
              const lnAmount =
                // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
                require("light-bolt11-decoder").decode(lnInvoice).sections[2]
                  .value * 0.001;
              if (lnBalance >= lnAmount / 100_000_000) {
                navigate("/lightningconfirm", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    hash,
                    lnCredentials,
                    usernameInvoice: null,
                    lnInvoice,
                  },
                });
              } else {
                setError({
                  title: "Error Paying Invoice",
                  message: "LN wallet balance too low",
                });
                setOpenError(true);
              }
            } catch (err) {
              setError({
                title: "Error Paying Invoice",
                message:
                  `${(err as Error)?.name}: ${(err as Error)?.message}` ||
                  "Unhandled exception",
              });
              setOpenError(true);
            }
          }}
        >
          <div className="w-full">
            <div className="w-full">
              <TextFieldWithButton
                handleOnInputChange={(value) => setLnInvoice(value)}
                handleOnClick={() => {
                  if (
                    !navigator.mediaDevices ||
                    !navigator.mediaDevices.getUserMedia
                  ) {
                    setError({
                      title: "Error Opening Camera",
                      message: "getUserMedia is not supported on this device",
                    });
                    setOpenError(true);
                    return;
                  }
                  initializeQRScanner();
                }}
                type="text"
                label="LN Invoice"
                buttonName="Scan QR"
                placeholder="Enter recipient's invoice"
                className="mt-4"
              />
            </div>
            <p className="w-full mt-6 text-base font-medium text-center text-gray-900 truncate dark:text-gray-500">
              <span className="font-light">LN wallet balance:</span>{" "}
              {lnBalance
                ? lnBalance.toLocaleString(undefined, {
                    minimumFractionDigits: 8,
                  })
                : 0}{" "}
              {network !== "bitcoin" ? "tBTC" : "BTC"}
            </p>
          </div>
        </PageWrapper>
      )}
      {option === "Username" && (
        <PageWrapper
          title="Send With LN Username"
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, lnCredentials, hash },
            })
          }
          handleSubmit={async () => {
            try {
              if (lnBalance >= usernameInvoice.amount / 100_000_000) {
                navigate("/lightningconfirm", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    hash,
                    lnCredentials,
                    usernameInvoice,
                    lnInvoice: null,
                  },
                });
              } else {
                setError({
                  title: "Error Paying Invoice",
                  message: "LN wallet balance too low",
                });
                setOpenError(true);
              }
            } catch (err) {
              setError({
                title: "Error Paying Invoice",
                message:
                  `${(err as Error)?.name}: ${(err as Error)?.message}` ||
                  "Unhandled exception",
              });
              setOpenError(true);
            }
          }}
        >
          <div className="w-full">
            <div className="w-full">
              <TextField
                type="text"
                label="Username"
                placeholder="Enter username"
                className="w-full p-3"
                onChange={(e) =>
                  setUsernameInvoice({
                    ...usernameInvoice,
                    username: e.target.value,
                  })
                }
              />
              <TextField
                type="number"
                label="Amount"
                placeholder="Enter amount (Sats)"
                className="w-full p-3"
                onChange={(e) =>
                  setUsernameInvoice({
                    ...usernameInvoice,
                    amount: e.target.value,
                  })
                }
              />
            </div>
            <p className="w-full mt-6 text-base font-medium text-center text-gray-900 truncate dark:text-gray-500">
              <span className="font-light">LN wallet balance:</span>{" "}
              {lnBalance
                ? lnBalance.toLocaleString(undefined, {
                    minimumFractionDigits: 8,
                  })
                : 0}{" "}
              {network !== "bitcoin" ? "tBTC" : "BTC"}
            </p>
          </div>
        </PageWrapper>
      )}
      {(option === "Asset" || option === "UDA") && (
        <PageWrapper
          title={`Send ${option}`}
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, hash, lnCredentials },
            })
          }
          handleSubmit={() => handleTokenSubmit()}
          loading={loading}
        >
          <div className="w-full">
            <div className="w-full">
              <TextFieldWithButton
                handleOnInputChange={async (value) => {
                  try {
                    const { contractId, amount: invoiceAmount } =
                      await backend.rgb.decodeInvoice(value);
                    rgbInvoice.setAmount(invoiceAmount);
                    setTransaction({
                      ...transaction,
                      amount: Number(rgbInvoice.getAmount()),
                      asset: contractId,
                      recipient: {
                        ...transaction.recipient,
                        invoice: value,
                        name: "recipient",
                      },
                    });
                  } catch (err) {
                    setValueError("Invalid invoice format");
                  }
                }}
                handleOnClick={() => {
                  if (
                    !navigator.mediaDevices ||
                    !navigator.mediaDevices.getUserMedia
                  ) {
                    setError({
                      title: "Error Opening Camera",
                      message: "getUserMedia is not supported on this device",
                    });
                    setOpenError(true);
                    return;
                  }
                  initializeQRScanner();
                }}
                type="text"
                label="To"
                buttonName="Scan QR"
                placeholder="Enter recipient's invoice"
                className="mt-4"
              />
            </div>
            {valueError && (
              <p className="text-base text-left text-red-700">{valueError}</p>
            )}
            <TextArea
              type="text"
              label="Note"
              placeholder="Enter note"
              className="w-full p-3"
              onChange={(e) =>
                setTransaction({ ...transaction, note: e.target.value })
              }
            />
            <SetFeeRate
              label="Set Fee Rate"
              optimalFee={optimalFee}
              fee={fee}
              onFeeChange={(f) => setFee(f)}
              onFeeRefresh={(f) => setOptimalFee(f)}
            />
          </div>
        </PageWrapper>
      )}
      {option === "Invoice" && (
        <PageWrapper
          title={`Send ${option}`}
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, hash, lnCredentials },
            })
          }
          handleSubmit={() =>
            navigate("/confirm", {
              state: {
                walletData,
                vault,
                lnCredentials,
                transaction,
                hash,
              },
            })
          }
        >
          <div className="w-full">
            <TextField
              label="To"
              onChange={(e) =>
                setTransaction({
                  ...transaction,
                  recipient: {
                    ...transaction.recipient,
                    address: e.target.value,
                    name: "recipient",
                  },
                })
              }
              placeholder="Enter recipient's address"
              className="w-full p-3"
            />
            <TextField
              label="Amount"
              type="number"
              onChange={(e) =>
                setTransaction({ ...transaction, amount: e.target.value })
              }
              placeholder="Enter amount"
              className="w-full p-3"
            />
            <TextArea
              type="text"
              label="Note"
              placeholder="Enter note"
              className="w-full p-3"
              onChange={(e) =>
                setTransaction({ ...transaction, note: e.target.value })
              }
            />
          </div>
        </PageWrapper>
      )}
      <ErrorModal
        open={openError}
        setOpen={setOpenError}
        message={error.message}
        title={error.title}
      />
      {scanning && (
        <FullScreenModal open={openQrScanner} setOpen={setOpenQrScanner}>
          <ScanQR
            setQr={async (res) => {
              stopScanning();
              setScanning(false);
              setLoading(true);
              switch (option) {
                case "Bitcoin":
                  setTransaction({
                    ...transaction,
                    recipient: {
                      ...transaction.recipient,
                      address: res,
                      name: "recipient",
                    },
                  });
                  break;
                case "Lightning":
                  try {
                    const lnAmount =
                      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
                      require("light-bolt11-decoder").decode(res).sections[2]
                        .value * 0.001;
                    if (lnBalance > lnAmount / 100_000_000) {
                      navigate("/lightningconfirm", {
                        state: {
                          wallet: walletData.name,
                          vault,
                          hash,
                          lnCredentials,
                          lnInvoice: res,
                        },
                      });
                    } else {
                      setError({
                        title: "Error Paying Invoice",
                        message: "LN wallet balance too low",
                      });
                      setOpenError(true);
                    }
                  } catch (err) {
                    setError({
                      title: "Error Paying Invoice",
                      message:
                        `${(err as Error)?.name}: ${(err as Error)?.message}` ||
                        "Unhandled exception",
                    });
                    setOpenError(true);
                  }
                  break;
                case "Asset":
                case "UDA": {
                  try {
                    const { contractId, amount: invoiceAmount } =
                      await backend.rgb.decodeInvoice(res);
                    rgbInvoice.setAmount(invoiceAmount);

                    const [isValid, reason] = rgbInvoice.isValid();
                    if (!isValid) {
                      setError(reason);
                      setOpenError(true);
                      return;
                    }

                    const result = await canTransferAssets(
                      vault,
                      asset,
                      res,
                      fee !== 0 ? fee : optimalFee
                    );

                    if (!result.valid) {
                      setError({
                        title: `Error sending payment`,
                        message:
                          result.message +
                          (result.message.includes("open invoices")
                            ? " Please close them before proceeding."
                            : ""),
                      });
                      setOpenError(true);
                      return;
                    }
                    if (isValid) {
                      const transactionData = {
                        amount: Number(rgbInvoice.getAmount()),
                        recipient: {
                          ...transaction.recipient,
                          invoice: res,
                          name: "recipient",
                        },
                        asset: rgbInvoice.contractId(),
                        assetType: rgbInvoice.asseType(),
                      };
                      navigate("/confirm", {
                        state: {
                          ...location.state,
                          transaction: transactionData,
                          feeRate: fee,
                          locationHistory: [
                            ...locationHistory,
                            window.location.hash,
                          ],
                        },
                      });
                      window.location.reload();
                    }
                  } catch (err) {
                    setError({
                      title: "Error processing RGB invoice",
                      message:
                        (err as Error).message ||
                        "An unexpected error occurred",
                    });
                    setOpenError(true);
                  }
                  return;
                }
                default:
                  return null;
              }
            }}
            open={openQrScanner}
            setOpen={(isOpen) => {
              if (!isOpen) {
                stopScanning();
                setScanning(false);
              }
              setOpenQrScanner(isOpen);
            }}
            setError={setError}
            setOpenError={setOpenError}
            stopScanning={stopScanning}
          />
        </FullScreenModal>
      )}
    </>
  );
};

export default Send;
