/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* global chrome */
import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import * as backend from "bitmask-core";
import { WalletData } from "bitmask-core/bitcoin";
import { LNDHUBX, init } from "bitmask-core/constants";

import Loader from "src/Components/Loaders/Loader";
import { MainNavContext } from "src/Hooks/MainNavContext";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import SecondaryNav from "src/Components/Navbar/SecondaryNav";
import ArrowDownCircle from "src/Components/Icons/ArrowDownCircle";
import Footer from "src/Components/Layout/Footers/Footer";
import lib, { isExtension } from "src/lib";
import useWindowSize from "src/Hooks/useWindowSize";
import { classNames, getHash } from "src/Hooks/util";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { v4 as uuidv4 } from "uuid";
import CustomSwiper from "src/Components/Swiper/CustomSwiper";
import RgbCard from "src/Components/Icons/Landing/RgbCard";
import CarbonadoCard from "src/Components/Icons/Landing/CarbonadoCard";
import WalletsCreated from "src/Components/Icons/Landing/WalletsCreated";
import RgbDive from "src/Components/Icons/Landing/RgbDive";

const Welcome = () => {
  const size = useWindowSize();
  const [height, setHeight] = useState(0);
  const { setMainNav } = useContext(MainNavContext);
  const navigate = useNavigate();
  const [view, setView] = useState(true);
  const [loggedIn, setLoggedIn] = useState(true);
  const [isVaultLoading, setIsVaultLoading] = useState(true);
  const dibaRef = useRef(null);
  const web3Ref = useRef(null);
  const blogRef = useRef(null);
  const heroRef = useRef<HTMLDivElement>(null);
  const [, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [heroHeight, setHeroHeight] = useState(0);
  const network = window.localStorage.getItem("network");

  const onSubmit = () => {
    if (email !== "") {
      const re = /\S+@\S+\.\S+/;
      if (re.test(email)) {
        setError(false);
        // TODO: send email
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };
  const CARD_OFFSET = 40;
  const SCALE_FACTOR = 0.06;

  const blogs = [
    {
      title: "Who is building Diba?",
      description: "Learn about the people buildng the DIBA products.",
      img: "/images/whoisbuildingdiba.png",
      link: "/blog/WhoIsBuildingDiba",
      id: uuidv4(),
    },
    {
      title: "Why This Design? The Story behind DIBA’s Merch.",
      description:
        "Merch without concept is just merch. It’s a sticker or shirt in the company’s color palette with elements like a logo, website or a bit of text.",
      img: "/images/merch1.webp",
      link: "/blog/WhyThisDesign",
      id: uuidv4(),
    },
    {
      title: "NFT NYC 2022 showcases Bitcoin Art with DIBA",
      description:
        "Art and Assets built on Bitcoin make their existence known into the mainstream NFT scene. DIBA presents on the NFT NYC Conference panel and attends 50+ surrounding events.",
      img: "/images/NFTNYC2022.jpeg",
      link: "/blog/NFTNYC2022",
      id: uuidv4(),
    },
    {
      title: "DIBA Presents at 2022 Bitcoin Conference",
      description:
        "This year, DIBA had a presence at the largest, most reputable Bitcoin event in the world through TALKS, DIGITAL ART, and DIBA’s first ever official AFTERPARTY.",
      img: "/images/DibaPresents2022BTCCon.webp",
      link: "/blog/2022BTCCon",
      id: uuidv4(),
    },
    {
      title: "BitMask on Chrome Store",
      description:
        "DIBA Global is proud to share the latest BitMask, the wallet for utility directly on bitcoin. Version 0.1.1.0 is available to download as a Google Chrome extension.",
      img: "/images/BitmaskonChromeStore.webp",
      link: "/blog/BitmaskonChrome",
      id: uuidv4(),
    },
    {
      title:
        "Diba is a Top 10 Finalist at Bitcoin Conference 2021 Pitch Competition",
      description:
        "Out of hundreds of submissions, Diba was selected to present their project as a Top 10 Finalist at the 2021 Bitcoin Conference in Miami on Whale Day.",
      img: "/images/DibaIsTopTen.webp",
      link: "/blog/DibaIsTop10",
      id: uuidv4(),
    },
  ];

  const spring = {
    type: "spring",
    damping: 10,
    stiffness: 80,
  };

  const handleRedirectToFullscreenTab = () => {
    window.close();
    const win = window.open(`${window.location.href.split("?")[0]}`, "_blank");
    win?.focus();
  };

  const handleRedirectToHomepageFullscreenTab = () => {
    window.close();
    const win = window.open(
      `${window.location.href.split("?")[0]}/#/home`,
      "_blank"
    );
    win?.focus();
  };

  const getWalletMode = () =>
    window.location.href.includes("chrome-extension://")
      ? "Extension"
      : "Web Wallet";

  function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        if (savedCallback.current) savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    setView(!view);
  }, 5000);

  useLayoutEffect(() => {
    setHeight(size.height - 56);
  }, [size]);

  useEffect(() => {
    (async () => {
      setLoggedIn(false);
      const isPopup = window.location.href.split("?").pop()?.includes("popup");

      setIsVaultLoading(true);

      if (localStorage.getItem(`${network}Descriptor`)) {
        await init(process.env.NETWORK_OVERRIDE);
        const result = await lib.storageGet<WalletData>(["wallet"]);

        const hash = await getHash();
        const encryptedDescriptor = localStorage.getItem(
          `${network}Descriptor`
        );

        if (
          typeof hash === "string" &&
          typeof encryptedDescriptor === "string"
        ) {
          const vault = await backend.bitcoin.decryptWallet(
            hash,
            encryptedDescriptor
          );

          let refresh = "";
          let token = "";
          if (LNDHUBX && network === "bitcoin") {
            const tokens = await backend.lightning.auth(
              vault.public.xpubkh,
              vault.private.xprvkh
            );
            if ("error" in tokens) {
              console.error(tokens.error);
              throw new Error(tokens.error);
            } else {
              refresh = tokens.refresh;
              token = tokens.token;
            }
          }
          if (window.location.hash.includes("home")) {
            if (
              isPopup &&
              (window.location.href.split("/").pop() === "index.html" ||
                window.location.href.split("/").pop() === "home")
            ) {
              handleRedirectToHomepageFullscreenTab();
            }
            setIsVaultLoading(false);
            localStorage.setItem("loggedIn", "true");
            localStorage.setItem("lockWallet", "false");
            setLoggedIn(true);
            navigate("/home", {
              replace: true,
            });
          } else {
            navigate("/wallet", {
              replace: true,
              state: {
                wallet: result.wallet?.name || "wallet_01",
                vault,
                lnCredentials: {
                  login: vault.public.xpubkh,
                  password: vault.private.xprvkh,
                  refreshToken: refresh,
                  accessToken: token,
                },
                hash,
              },
            });
          }
        } else if (window.location.hash.includes("home")) {
          if (
            isPopup &&
            (window.location.href.split("/").pop() === "index.html" ||
              window.location.href.split("/").pop() === "home")
          ) {
            handleRedirectToHomepageFullscreenTab();
          }
          setIsVaultLoading(false);
          setLoggedIn(true);
          localStorage.setItem("loggedIn", "true");
          localStorage.setItem("lockWallet", "false");
        } else {
          localStorage.setItem("lockWallet", "true");
          navigate("/signin", {
            replace: true,
          });
        }
      } else if (
        isPopup &&
        window.location.href.split("/").pop()?.startsWith("index.html")
      ) {
        setIsVaultLoading(false);
        handleRedirectToFullscreenTab();
      } else {
        setIsVaultLoading(false);
      }
    })();
  }, []);

  if (isVaultLoading) {
    return (
      <div
        className={classNames(
          isExtension ? "min-w-[400px]" : "min-w-80",
          "flex justify-center w-full h-screen m-auto text-gray-300 bg-darkmode-900"
        )}
      >
        <Loader className="w-20 h-20 m-auto" />
      </div>
    );
  }

  return (
    <div className="z-50 flex flex-col justify-center h-full min-h-full overflow-hidden bg-darkmode-900">
      <SecondaryNav
        className="text-white bg-darkmode-900"
        menuItems={[
          loggedIn && { name: "Wallet", route: "/wallet" },
          // { name: "Deep Web3", ref: web3Ref },
          // { name: "DIBA", ref: dibaRef },
          { name: "Blog", ref: blogRef },
          { name: "FAQ", route: "/faq" },
          { name: "Registry", route: "/registry" },
          { name: "Metrics", route: "/metrics" },
          { name: "GitHub", link: "https://github.com/diba-io/bitmask-core/" },
        ]}
      />
      <div
        style={{
          height,
          minHeight: height,
        }}
        className="w-full h-auto my-auto overflow-y-auto darkscrollbar"
      >
        <div className="flex flex-col justify-center h-auto">
          <div className="h-full min-h-full">
            <div className="flex flex-col justify-center w-full h-auto m-auto">
              <section className="relative flex flex-col justify-center h-auto mx-auto overflow-hidden sm:pt-6 md:pt-12 xl:my-auto min-h-page bg-darkmode-900">
                <div
                  ref={heroRef}
                  className="flex flex-col w-11/12 h-auto max-w-3xl mx-auto mt-auto text-center text-white"
                >
                  <div className="relative z-20 w-full m-auto text-white whitespace-pre-wrap xs:mx-3 xl:mx-0">
                    <h1 className="text-4xl font-medium sm:text-5xl">
                      BitMask wallet
                      <br /> your Bitcoin swiss army knife
                    </h1>
                    <p className="mt-6 text-base font-thin tracking-normal text-gray-500 xs:text-lg leading-7 sm:leading-8 lg:p-0 md:tracking-wide">
                      The Browser Extension for Decentralized Applications on
                      Bitcoin. Access Bitcoin Finance, NFTs (UDAs) and more
                      using RGB protocol, with full financial sovereignty
                      enabled through our taproot-enabled Bitcoin and Lightning
                      Network wallet.
                    </p>{" "}
                  </div>
                  <div className="flex justify-center w-full pb-1.5 sm:pb-4">
                    <div className="relative flex flex-row justify-center w-full mx-auto my-3 text-gray-900 sm:mt-6 lg:mt-9 gap-6">
                      {loggedIn ? (
                        <RoundedButton
                          className="z-20 w-auto px-6 py-3 mx-auto bg-yellow-500 rounded-4xl"
                          onClick={() => navigate("/wallet")}
                        >
                          <p className="mx-auto my-auto text-lg lg:text-xl">
                            Open Wallet
                          </p>
                        </RoundedButton>
                      ) : (
                        <>
                          {isExtension ? (
                            <RoundedButton
                              style={{ background: "#272F3F" }}
                              className="z-20 w-auto px-6 py-3 mx-auto bg-yellow-500 rounded-4xl"
                              onClick={() => navigate("/newuser")}
                            >
                              <p className="mx-auto my-auto text-base sm:text-lg">
                                Continue with Extension
                              </p>
                            </RoundedButton>
                          ) : (
                            <>
                              <RoundedButton
                                style={{ background: "#272F3F" }}
                                className="z-20 w-auto h-12 p-3 bg-gray-100 rounded-4xl"
                                onClick={() =>
                                  window.open(
                                    "https://chrome.google.com/webstore/detail/bitmask/momakdpclmaphlamgjcndbgfckjfpemp",
                                    "_blank"
                                  )
                                }
                              >
                                <img
                                  className="object-contain w-auto h-10 mx-auto my-auto"
                                  src="/images/extensionDownload.png"
                                  alt="Google chrome extension download"
                                />
                              </RoundedButton>
                              <RoundedButton
                                className="z-20 flex justify-center w-auto h-full px-6 my-auto text-xs bg-yellow-500 min-h-12 rounded-4xl xs:text-sm sm:text-lg max-h-12"
                                onClick={() => navigate("/newuser")}
                              >
                                Continue with Web Wallet
                              </RoundedButton>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    "z-50 flex w-full h-auto max-w-full mx-auto mb-auto"
                  )}
                >
                  <img
                    className={classNames(
                      "h-auto object-cover w-[150%] sm:object-contain object-top hidden md:flex"
                    )}
                    src="/images/hero.png"
                    alt="macbook with bitmask extension open"
                  />
                  <img
                    className={classNames(
                      "h-auto object-cover w-[150%] sm:object-contain object-top md:hidden flex"
                    )}
                    src="/images/herosmall.png"
                    alt="macbook with bitmask extension open"
                  />
                </div>
              </section>
              <section className="relative hidden h-auto my-auto overflow-hidden bg-darkmode-900">
                <div className="flex flex-row order-1 w-10/12 h-auto m-auto text-white col-span-6 5xl:mr-0 5xl:ml-auto 4xl:col-span-5 py-9">
                  <div className="relative z-20 flex flex-col justify-center w-full m-auto text-white">
                    <h1 className="mr-6 text-xs text-left md:text-sm lg:text-base md:text-left">
                      Unlock product potential and insider tips. Sign up for our
                      exclusive newsletter.
                    </h1>
                  </div>
                  <div className="flex flex-row w-full xs:mx-auto 2xl:flex-row">
                    <input
                      id="link"
                      name="link"
                      className="flex-auto w-auto h-12 py-3 pl-2 pr-3 my-4 mr-auto text-base font-light text-gray-300 placeholder-gray-500 rounded-lg min-w-52 xs:max-w-full border-darkmode-100 sm:mr-4 xs:min-w-64 2xl:w-auto lg:max-w-96 placeholder-darkmode-100 bg-newdarkmode-700 border-1/2 focus:outline-none focus:text-gray-300 focus:placeholder-gray-500 focus:ring-1 focus:ring-green-400 focus:border-green-400 sm:text-sm"
                      placeholder="Enter Email"
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value.trim());
                        setSuccess(false);
                      }}
                      onKeyDown={(e) => (e.key === "Enter" ? onSubmit() : null)}
                    />
                    {email !== "" && /\S+@\S+\.\S+/.test(email) === false && (
                      <p className="mb-4 text-sm font-thin tracking-wide text-gray-500 md:text-base xl:hidden">
                        Please enter a valid email address.
                      </p>
                    )}
                    <RoundedButton
                      onClick={() => onSubmit()}
                      className="flex w-auto h-12 mx-auto my-auto ml-3 font-bold text-black bg-yellow-500 rounded-lg md:px-14"
                    >
                      Subscribe
                    </RoundedButton>
                  </div>
                  {email !== "" && /\S+@\S+\.\S+/.test(email) === false && (
                    <p className="hidden my-auto ml-8 text-sm font-thin tracking-wide text-gray-500 md:text-base xl:block">
                      Please enter a valid email address
                    </p>
                  )}
                  {success && (
                    <p className="my-auto ml-8 text-sm font-thin tracking-wide text-gray-500 md:text-base">
                      Thank you! We will keep you in the loop.
                    </p>
                  )}
                </div>
              </section>
              <section
                ref={web3Ref}
                id="web3"
                className="relative w-11/12 h-auto m-auto overflow-hidden bg-darkmode-900"
              >
                <div className="mx-auto my-9 md:my-20">
                  <div className="flex flex-col w-full h-auto m-auto text-center text-white">
                    <div className="z-20 mx-auto grid grid-cols-12 gap-6">
                      <a
                        href="https://bitcoin.org/en/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white col-span-12 sm:col-span-6"
                      >
                        <div className="relative hover:scale-[105%] duration-300 z-20 w-full h-auto rounded-xl py-3 xs:py-6 px-1.5 xs:px-3 bg-darkmode-800 text-white">
                          <p className="mx-2 mb-3 text-lg text-left">
                            Bitcoin Network
                          </p>
                          <p className="mx-3 text-base font-thin text-left text-gray-500">
                            A trustless and decentralized settlement layer,
                            public ledger and timechain.
                          </p>
                        </div>
                      </a>
                      <a
                        href="https://lightning.network/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white col-span-12 sm:col-span-6"
                      >
                        <div className="relative hover:scale-[105%] duration-300 z-20 w-full h-auto rounded-xl py-3 xs:py-6 px-1.5 xs:px-3 bg-darkmode-800 text-white">
                          <p className="mx-2 mb-3 text-lg text-left">
                            Lightning Network{" "}
                          </p>
                          <p className="mx-3 text-base font-thin text-left text-gray-500">
                            The payment layer with very low fees and 40 million
                            transactions per second.
                          </p>
                        </div>
                      </a>
                      <a
                        href="https://rgb.info/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white col-span-12 sm:col-span-8"
                      >
                        <div className="relative hover:scale-[105%] duration-300 z-20 w-full h-auto rounded-xl py-3 xs:py-6 px-1.5 xs:px-3 bg-darkmode-800 text-white">
                          <RgbCard className="w-full h-auto" />
                          <p className="mx-2 mb-3 text-lg text-left">
                            RGB Smart-Contract{" "}
                          </p>
                          <p className="mx-3 text-base font-thin text-left text-gray-500">
                            A client-side validated state and smart contracts
                            system operating on Layer 2 and 3 of Bitcoin
                            ecosystem.
                          </p>
                        </div>
                      </a>
                      <a
                        href="https://github.com/diba-io/carbonado"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white col-span-12 sm:col-span-4"
                      >
                        <div className="relative hover:scale-[105%] duration-300 z-20 w-full h-auto rounded-xl py-3 xs:py-6 px-1.5 xs:px-3 bg-darkmode-800 text-white">
                          <CarbonadoCard className="w-full h-auto" />
                          <p className="mx-2 mb-3 text-lg text-left">
                            Carbonado{" "}
                          </p>
                          <p className="mx-3 text-base font-thin text-left text-gray-500">
                            An apocalypse-resistant data storage format for the
                            truly paranoid.
                          </p>
                        </div>
                      </a>
                      <div className="text-white col-span-12 sm:col-span-6">
                        <div className="relative hover:scale-[105%] duration-300 z-20 w-full h-auto rounded-xl py-3 xs:py-6 px-1.5 xs:px-3 bg-darkmode-800 text-white">
                          <p className="mx-2 mb-3 text-lg text-left">BMT</p>
                          <p className="mx-3 text-base font-thin text-left text-gray-500">
                            BitMask Token for Native Bitcoin Finance where the
                            only FEE token is BTC
                          </p>
                        </div>
                      </div>
                      <a
                        href="https://nostr.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white col-span-12 sm:col-span-6"
                      >
                        <div className="relative hover:scale-[105%] duration-300 z-20 w-full h-auto rounded-xl py-3 xs:py-6 px-1.5 xs:px-3 bg-darkmode-800 text-white">
                          <p className="mx-2 mb-3 text-lg text-left">Nostr</p>
                          <p className="mx-3 text-base font-thin text-left text-gray-500">
                            A decentralized network protocol for a distributed
                            social networking system.
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              <section className="relative flex flex-col justify-center h-auto my-auto overflow-hidden py-9 sm:py-12">
                <div className="flex flex-col justify-center w-auto max-w-3xl mx-auto">
                  <p className="mx-auto text-3xl font-medium text-center sm:text-4xl lg:block">
                    Over 1,000,000 wallets created
                  </p>
                  <p className="mx-3 my-6 text-base font-thin text-center text-gray-500">
                    Discover the ultimate smart contracts wallet, boasting
                    top-tier security and ease of use. With over 1,000,000
                    wallets created worldwide, our platform stands as the
                    premier choice for safeguarding RGB20 and RGB21 digital
                    assets.
                  </p>
                  <RoundedButton
                    style={{ background: "#272F3F" }}
                    className="z-20 p-3 mx-auto mb-12 bg-gray-100 w-36 rounded-4xl"
                    onClick={() =>
                      window.open(
                        "https://chrome.google.com/webstore/detail/bitmask/momakdpclmaphlamgjcndbgfckjfpemp",
                        "_blank"
                      )
                    }
                  >
                    <img
                      className="object-contain w-auto h-10 mx-auto my-auto"
                      src="/images/extensionDownload.png"
                      alt="Google chrome extension download"
                    />
                  </RoundedButton>
                </div>
                <WalletsCreated className="w-full h-auto" />
              </section>
              <section className="relative flex flex-col justify-center h-auto my-auto overflow-hidden py-9 sm:py-12">
                <div className="flex flex-col justify-center w-auto max-w-3xl mx-auto">
                  <p className="mx-auto text-3xl font-medium sm:text-4xl lg:block">
                    RGB deep dive
                  </p>
                  <p className="mx-3 my-6 text-base font-thin text-center text-gray-500">
                    RGB is an open-source protocol that enhances Bitcoin's
                    privacy and scalability , thereby facilitating the execution
                    of complex smart contracts. It utilizes the Bitcoin
                    blockchain to manage a smart contract's code and data
                    off-chain.
                  </p>
                  <RoundedButton
                    className="z-20 w-auto px-6 py-3 mx-auto mb-12 text-black bg-yellow-500 rounded-4xl"
                    onClick={() =>
                      window.open("https://rgb.tech/docs", "_blank")
                    }
                  >
                    Learn more about RGB
                  </RoundedButton>
                </div>
                <RgbDive className="w-full h-auto" />
              </section>
              <section
                className="px-6 py-9 md:py-20 bg-darkmode-900"
                id="blog"
                ref={blogRef}
              >
                <CustomSwiper>
                  <div className="p-3 mx-6 sm:p-12">
                    {blogs.map((blog) => (
                      <SwiperSlide className="swiper-slide" key={blog.id}>
                        <div className="w-auto h-auto mb-12 xs:mt-6 md:mx-3 min-h-112 rounded-xl">
                          <div className="w-auto overflow-hidden cursor-pointer rounded-xl">
                            <Link to={blog.link} key={blog.id}>
                              <div className="relative z-0 select-none h-72">
                                <img
                                  className="object-cover w-full h-full pointer-events-none select-none rounded-t-xl"
                                  src={blog.img}
                                  crossOrigin="anonymous"
                                  alt="Blog post thumbnail"
                                />
                              </div>
                              <div className="z-10 h-auto px-4 pt-3 pb-2 text-gray-200 select-none">
                                <h3 className="pb-2 text-base font-thin text-center text-white truncate md:text-lg font-integral">
                                  {blog.title}
                                </h3>
                                <p className="mx-3 my-auto overflow-hidden text-sm font-light text-center md:text-base overflow-ellipsis line-clamp-3 leading-7">
                                  {blog.description}
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </div>
                </CustomSwiper>
              </section>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Welcome;
